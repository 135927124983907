<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">
		<PreviewModal />
        <div class="contacts-page"> 

            <BHLoading :show="loadingPage" />
            <h3 v-if="origBricks && origBricks.length" class="mb-3">Exterior Color Packages</h3>
            <template v-if="origBricks && origBricks.length">
                <BricksTable />
            </template>
        </div>
        <template v-if="!origBricks.length">
            <EmptyApp :text="'You don\'t have any Exterior Color Packages'" :img="require('@/assets/emptyIcons/empty-bricks.png')" />
        </template>

    </div>
</template>

<script>
    import {getBase64,arrToObj,formatDate} from 'bh-mod'
    import BHLoading from 'bh-mod/components/common/Loading'
    import FeaturesTable from '@/components/features/FeaturesTable'
    import BricksTable from '@/components/features/BricksTable'
	import PreviewModal from '@/components/features/PreviewModal'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    export default {
        components:{
            BHLoading,FeaturesTable,BricksTable,EmptyApp,PreviewModal
        },
        data() {
            return {
                duplicate:{
                    name:'',
                    id:'',
                    show:false,
                    load:false
                },
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1'
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            currentPage:{
                get(){
                    return this.$store.state.features.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            newStoreTime(){
                return this.$store.state.features.newStoreTime
            },
            origBricks(){
                return Object.values(this.$store.state.features.bricks)
            },
            loadingPage:{
                get(){
                    return this.$store.state.features.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts 
                return (delivered / contacts.length) * 100
            },
            itemMenuClick( { item, key, keyPath },broadcast) {
                console.log('BROADCAST CLICKED',broadcast,key)
                if (key === 'dup'){
                    this.dupTemplate(broadcast.email)
                } else if (key === 'report'){
                    this.$message.error('This functionality is currently not available')
                }
            },
            async handleDuplicate(){

                this.duplicate.load = true 

                let {id,name} = this.duplicate
                let {data} = await this.$api.post(`/emails/${this.instance.id}/clone`,{id,name})
                
                this.$store.commit('SHOW_EDIT_TEMPLATE',data)
                setTimeout(() => {
                    this.duplicate.load = false
                    this.closeDuplicate()
                }, 1000);

                // console.log('COPIED TEMPLATE CLONEDD******* ',data)
            },
            openTemplate(template){
                console.log('TEMPLATE')
                this.$store.commit('SHOW_EDIT_TEMPLATE',template)
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
           
        },
        created(){
            
        },
        mounted(){

        },
        created() {
            console.log('sdafklsadjflkasdjl')

            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
            
        }

    }
</script>

<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:2%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }
    
    
        
</style>

